<template>
  <div id="home-view">
    <div v-if="isDevelopment" class="development-banner">
      Test Environment
    </div>
    <app-slider />
    <div class="container my-4">
      <!-- Wizard Section -->
      <section class="wizard-section">
        <img src="@/assets/site/images/wizard.png" alt="wizard image" />
        <div @click="showWizard()" class="button">
          Start Gifting
        </div>
        <h1 class="text">
          The World's First <span class="blue">AI</span> Gift Wizard
        </h1>
      </section>
      <!-- Trending Gifts Section -->
      <section v-if="trendingProducts.length > 0" class="best-sellers-section">
        <h3 class="best-sellers-section-title">
          TRENDING GIFTS
        </h3>
        <div class="best-sellers">
          <div
            @click="ViewProduct(bestSeller)"
            class="best-seller"
            v-for="bestSeller in trendingProducts"
          >
            <div class="image-wrapper">
              <img
                v-if="bestSeller.imageurl"
                class="product-image-photo lazyload"
                :src="bestSeller.imageurl"
                @error="() => (bestSeller.imageurl = null)"
                data-src=""
                loading="lazy"
                alt="Best Seller Product Image"
              />
              <img
                v-else
                class="product-image-photo lazyload"
                src="@/assets/site/images/noimage.jpeg"
                data-src=""
                loading="lazy"
                alt="Best Seller Product Image"
              />
              <div class="icons">
                <div
                  @click.stop="savetoFavourite(bestSeller)"
                  title="Add to Favourite"
                  class="icon"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path
                      d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"
                    />
                  </svg>
                </div>
                <div
                  @click.stop="savetoWishlist(bestSeller)"
                  title="Add to Wish List"
                  class="icon"
                >
                  <svg width="20" height="20" viewBox="0 0 512 512">
                    <path
                      d="M190.5 68.8L225.3 128l-1.3 0-72 0c-22.1 0-40-17.9-40-40s17.9-40 40-40l2.2 0c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40L32 128c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l448 0c17.7 0 32-14.3 32-32l0-64c0-17.7-14.3-32-32-32l-41.6 0c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88l-2.2 0c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0L152 0C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40l-72 0-1.3 0 34.8-59.2C329.1 55.9 342.9 48 357.8 48l2.2 0c22.1 0 40 17.9 40 40zM32 288l0 176c0 26.5 21.5 48 48 48l144 0 0-224L32 288zM288 512l144 0c26.5 0 48-21.5 48-48l0-176-192 0 0 224z"
                    />
                  </svg>
                </div>
                <div
                  @click.stop="compareProduct(bestSeller)"
                  title="Add to Compare"
                  class="icon"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      opacity="0.1"
                      d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
                      fill="#fe3c00"
                    />
                    <path
                      opacity="0.1"
                      d="M21 18C21 19.6569 19.6569 21 18 21C16.3431 21 15 19.6569 15 18C15 16.3431 16.3431 15 18 15C19.6569 15 21 16.3431 21 18Z"
                      fill="#fe3c00"
                    />
                    <path
                      d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
                      stroke="#fe3c00"
                      stroke-width="2"
                    />
                    <path
                      d="M21 18C21 19.6569 19.6569 21 18 21C16.3431 21 15 19.6569 15 18C15 16.3431 16.3431 15 18 15C19.6569 15 21 16.3431 21 18Z"
                      stroke="#fe3c00"
                      stroke-width="2"
                    />
                    <path
                      d="M15 3L12.0605 5.93945V5.93945C12.0271 5.97289 12.0271 6.02711 12.0605 6.06055V6.06055L15 9"
                      stroke="#fe3c00"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9 21L11.9473 18.0527V18.0527C11.9764 18.0236 11.9764 17.9764 11.9473 17.9473V17.9473L9 15"
                      stroke="#fe3c00"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M12 6C14.8284 6 16.2426 6 17.1213 6.87868C18 7.75736 18 9.17157 18 12V15"
                      stroke="#fe3c00"
                      stroke-width="2"
                    />
                    <path
                      d="M12 18C9.17157 18 7.75736 18 6.87868 17.1213C6 16.2426 6 14.8284 6 12L6 9"
                      stroke="#fe3c00"
                      stroke-width="2"
                    />
                  </svg>
                </div>
                <div
                  @click.stop="ViewProduct(bestSeller)"
                  title="Quick View"
                  class="icon"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                    <path
                      d="M572.5 241.4C518.3 135.6 410.9 64 288 64S57.7 135.6 3.5 241.4a32.4 32.4 0 0 0 0 29.2C57.7 376.4 165.1 448 288 448s230.3-71.6 284.5-177.4a32.4 32.4 0 0 0 0-29.2zM288 400a144 144 0 1 1 144-144 143.9 143.9 0 0 1 -144 144zm0-240a95.3 95.3 0 0 0 -25.3 3.8 47.9 47.9 0 0 1 -66.9 66.9A95.8 95.8 0 1 0 288 160z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="product-line"></div>
            <a
              data-post=""
              @click.stop="selectOccasion(bestSeller)"
              class="trending-product-wishlist"
              data-action="add-to-wishlist"
              title="Add to Favourite"
            >
              <svg width="20" height="20" viewBox="0 0 512 512">
                <path
                  d="M190.5 68.8L225.3 128l-1.3 0-72 0c-22.1 0-40-17.9-40-40s17.9-40 40-40l2.2 0c14.9 0 28.8 7.9 36.3 20.8zM64 88c0 14.4 3.5 28 9.6 40L32 128c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l448 0c17.7 0 32-14.3 32-32l0-64c0-17.7-14.3-32-32-32l-41.6 0c6.1-12 9.6-25.6 9.6-40c0-48.6-39.4-88-88-88l-2.2 0c-31.9 0-61.5 16.9-77.7 44.4L256 85.5l-24.1-41C215.7 16.9 186.1 0 154.2 0L152 0C103.4 0 64 39.4 64 88zm336 0c0 22.1-17.9 40-40 40l-72 0-1.3 0 34.8-59.2C329.1 55.9 342.9 48 357.8 48l2.2 0c22.1 0 40 17.9 40 40zM32 288l0 176c0 26.5 21.5 48 48 48l144 0 0-224L32 288zM288 512l144 0c26.5 0 48-21.5 48-48l0-176-192 0 0 224z"
                />
              </svg>
            </a>
            <div class="content">
              <p class="best-seller-title">
                {{ bestSeller.productname }}
              </p>
              <div class="best-seller-price">
                {{ bestSeller.saleprice["@currency"] }}
                {{ bestSeller.saleprice["#text"] }}
              </div>
              <div class="best-seller-rating">
                <svg width="12" height="12" viewBox="0 0 576 512">
                  <path
                    d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                  />
                </svg>
                <svg width="12" height="12" viewBox="0 0 576 512">
                  <path
                    d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                  />
                </svg>
                <svg width="12" height="12" viewBox="0 0 576 512">
                  <path
                    d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                  />
                </svg>
                <svg width="12" height="12" viewBox="0 0 576 512">
                  <path
                    d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"
                  />
                </svg>
                <svg width="12" height="12" viewBox="0 0 576 512">
                  <path
                    d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- Customer Slider Section -->
      <section class="home-customer-slider-wrapper">
        <div
          class="home-customer-slider"
          :style="{ animationDuration: customerSliders.length * 5 + 's' }"
        >
          <img
            v-for="slider in customerSliders"
            :src="slider.customer_slider"
            loading="lazy"
            alt="customer slider"
          />
        </div>
        <div
          class="home-customer-slider"
          :style="{ animationDuration: customerSliders.length * 5 + 's' }"
        >
          <img
            v-for="slider in customerSliders"
            :src="slider.customer_slider"
            loading="lazy"
            alt="customer slider"
          />
        </div>
      </section>
    </div>
    <!-- End Footer -->
    <wizard-view
      v-bind:wizard_data="wizard_data"
      v-bind:modal_id="modal_id"
    ></wizard-view>
    <!-- <b-modal ref="appModal" size="sm" hide-footer centered hide-header>
      <div class="mobile-app-popup">
        <svg
          @click="closeAppPopup()"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 384 512"
          class="close-icon"
        >
          <path
            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"
          />
        </svg>
        <p class="gift-text">Find The Perfect Gift <sup>TM</sup></p>
        <h3>It seems you are visiting our website on mobile.</h3>
        <p>
          using our mobile app, you can unlock countless features' lock. Try our
          mobile app!
        </p>

        <a
          v-if="isIos"
          href="https://apps.apple.com/in/app/govava/id1555186188"
          target="_blank"
          title="App Store"
          ><img src="@/assets/site/images/new/app-store.png" alt="App Store"
        /></a>
        <a
          v-else
          href="https://apps.apple.com/in/app/govava/id1555186188"
          target="_blank"
          title="Play  Store"
          ><img src="@/assets/site/images/new/android.png" alt="Play Store"
        /></a>
      </div>
    </b-modal> -->
    <div>
      <occasion-modal v-on:savetoWishlist="savetoWishlist"></occasion-modal>
    </div>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel2";
import Vue from "vue";
import BackToTop from "vue-backtotop";
import WizardView from "@/views/site/wizard/WizardView";
import OccasionModal from "@/views/site/OccasionModal";
import AppSlider from "@/views/site/blocks/AppSlider";

Vue.use(BackToTop);
export default {
  name: "HomeView",
  components: {
    carousel,
    OccasionModal,
    WizardView,
    AppSlider,
  },
  metaInfo: {
    title: "The World's First AI Gift Wizard",
    meta: [
      {
        name: "description",
        content:
          "Discover the world's first AI Gift Wizard! Explore trending gifts, save favorites, and find the perfect present effortlessly. Join us for an exciting shopping experience!",
      },
      {
        name: "keywords",
        content:
          "AI Gift Recommendations,Personalized Gifts,Gift Finder App,Interactive Avatar,Gift Shopping,Gift Ideas for Pets,AI Gift Wizard,Gift Suggestions,Real-Time Gift Suggestions,Custom Gifts,Occasion-Specific Gifts,Pet Gift Ideas,Smart Gift Assistant,Lifestyle-Based Gifts,AI Shopping Assistant,Pet-Friendly Gifts,Gift Planning,Gift Curation,Holiday Gift Ideas,Birthday Gift Suggestions",
      },
    ],
  },
  data() {
    return {
      // Wizard Related Data
      menu: null,
      menus: [],
      wizard_data: null,
      gender: null,
      modal_id: null,

      userData: {},
      loader: false,
      errors: [],
      pages: [],
      products: [],
      trendingProducts: [],
      friend: null,
      slides: [],
      blogs: [],
      customerSliders: [],
      siteBanners: [],
      showslider: false,
      description: "",
      slide_interval: 5000,
      slide_index: 0,
      occasion_id: null,
      wishlist_product: null,
      intervals: null,
      isIos: true,
      isDevelopment: process.env.VUE_APP_ENV === "development",
    };
  },
  mounted() {
    var token = localStorage.getItem("userData");
    this.userData = token;

    this.intervals = setInterval(() => {
      if (token) this.getTrendingProducts();
      this.getMenuLists();
      this.getCategoryList();
      this.getPages();
      this.getBannerImages();
      this.getCustomerSliders();
    }, 300000);

    if (
      this.isMobile(window.navigator.userAgent) &&
      !localStorage.getItem("app-popup")
    ) {
      this.isIos = navigator.userAgent.match(/iPhone|iPod/i);
      this.$refs.appModal.show();
      localStorage.setItem("app-popup", true);
    }
  },
  created() {
    const token = localStorage.getItem("userData");
    this.getMenuLists();
    this.getCategoryList();
    this.getPages();
    this.getBannerImages();
    this.getCustomerSliders();
    if (token) this.getTrendingProducts();
  },
  beforeDestroy() {
    clearInterval(this.intervals);
  },
  methods: {
    isMobile(device) {
      return (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          device
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          device.substr(0, 4)
        )
      );
    },
    closeAppPopup() {
      this.$refs.appModal.hide();
    },
    // Wizard Related Methods
    getCategoryList() {
      var fetch_url =
        process.env.VUE_APP_URL + "customer/category/categoryList";
      fetch(fetch_url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.categories = data.categories;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    getMenuLists() {
      var fetch_url = process.env.VUE_APP_URL + "customer/menu/menuList";
      fetch(fetch_url, {
        method: "get",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.menus = data.menus;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    showWizard() {
      const token = localStorage.getItem("userData");
      const wizardUsage = localStorage.getItem("wizardUsage") ?? 0;

      if (wizardUsage >= 3 && !token) $("#myModal").modal("show");
      else {
        this.modal_id = "menubar_wizard_modal";
        this.$bvModal.show("menubar_wizard_modal");
      }
    },
    onSlideChange(slideNumber) {
      this.slide_interval = 5000;
      if (this.slides[slideNumber].file_type == "Video") {
        const videoEl = document.querySelector(`#slide-${slideNumber}`);
        if (videoEl) {
          videoEl.currentTime = 0;
          videoEl.play();
        }
        this.slide_interval = 60000000;
      }
      this.slide_index = slideNumber;
    },
    getCustomerSliders() {
      this.errors = [];
      let method_type = "";
      let fetch_url = "";
      method_type = "get";
      fetch_url =
        process.env.VUE_APP_URL + "customer/page/getCustomerSliderImages";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.customerSliders = data.slider;
        });
    },
    getTrendingProducts() {
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var api_token = userdata_array.token;
      var country_code = localStorage.getItem("countryCode");
      var countryCode = JSON.parse(country_code);
      var fetch_url =
        process.env.VUE_APP_URL + "customer/products/getBestSellers";
      fetch(fetch_url, {
        method: "post",
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: "Bearer " + api_token,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.trendingProducts = data.response.items;
        })
        .catch(function(err) {
          console.log("Fetch Error :-S", err);
        });
    },
    getProducts() {
      this.errors = [];
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      if (token == null) {
        alert("Please log in to continue");
      } else {
        var user_id = userdata_array.user.id;
        let method_type = "";
        let fetch_url = "";
        method_type = "post";
        fetch_url = process.env.VUE_APP_URL + "customer/products/getProducts";
        fetch(fetch_url, {
          method: method_type,
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
          body: JSON.stringify({
            user_id: user_id,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            this.products = data.response.items;
          });
      }
    },
    getPages() {
      this.errors = [];
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url = process.env.VUE_APP_URL + "customer/page/getPages";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.pages = data.page;
        });
    },
    getSliderImages() {
      this.errors = [];
      let method_type = "";
      let fetch_url = "";
      method_type = "get";
      fetch_url = process.env.VUE_APP_URL + "customer/page/getSliderImages";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.slides = data.slider;
          if (this.slides.length != 0) {
            this.showslider = true;
            if (data.slider[0].file_type === "Video") {
              this.onSlideChange(0);
              this.slide_interval = 60000000;
            }
          }
        });
    },
    getBannerImages() {
      this.errors = [];
      var token = localStorage.getItem("userData");
      let method_type = "";
      let fetch_url = "";
      method_type = "get";
      fetch_url = process.env.VUE_APP_URL + "customer/page/getBannerImages";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.siteBanners = data.banner;
        });
    },
    selectOccasion(product) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        $("#myModal").modal("show");
      } else {
        this.wishlist_product = product;
        this.$bvModal.show("occasion-modal");
      }
    },
    savetoWishlist(product) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        $("#myModal").modal("show");
      } else {
        //Save or update User Details
        this.$bvModal.hide("occasion-modal");
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);

        var api_token = userdata_array.user.api_token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/userwishlist";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: product.productId,
            product: product,
            user_id: user_id,
            wishlist_type: "User",
            occasion_id: product.id,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.loader = false;

              this.$swal.fire("Error", data.message, "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });

        var fetch_url =
          process.env.VUE_APP_URL +
          "customer/products/saveAffilativeNetwork/" +
          this.productId;
        fetch(fetch_url, {
          method: "get",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
        })
          .then((res) => res.json())
          .then((data) => {});

        this.wishlist_product = null;
      }
    },
    compareProduct(bestSeller) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        $("#myModal").modal("show");
      } else {
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.user.api_token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/compare";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: bestSeller.productId,
            product: bestSeller,
            user_id: user_id,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;
              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.loader = false;
              this.$swal.fire("Error", data.message, "error");
            } else {
              this.loader = false;
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
      }
    },
    checkWizardProducts() {
      var loc_wizardProducts = localStorage.getItem("wizardProducts");
      if (loc_wizardProducts == null) {
        var wizardproducts = JSON.parse(loc_wizardProducts);
        this.products = wizardproducts.items;
        localStorage.removeItem("wizardProducts");
      } else {
        this.getProducts();
      }
    },
    ViewProduct(bestSeller) {
      this.$store.state.wizard_type = "User";
      const id = Math.random()
        .toString(36)
        .slice(2);
      this.$router.push({
        path: "/single-product/product/" + id,
        query: { singleproduct: bestSeller },
      });
    },
    savetoFavourite(bestSeller) {
      var loc_UserData = localStorage.getItem("userData");
      if (loc_UserData == null) {
        $("#myModal").modal("show");
      } else {
        var token = localStorage.getItem("userData");
        var userdata_array = JSON.parse(token);
        var api_token = userdata_array.user.token;
        var user_id = userdata_array.user.id;
        var fetch_url = process.env.VUE_APP_URL + "customer/favourite";
        fetch(fetch_url, {
          method: "post",
          headers: {
            "content-type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: "Bearer " + api_token,
          },
          body: JSON.stringify({
            product_id: bestSeller.productId,
            product: bestSeller,
            user_id: user_id,
            user_action: "AddToFav",
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            this.loader = false;
            this.errors = [];
            if (data.status === true) {
              this.loader = false;

              this.$swal.fire("Good to Go", data.message, "success");
            } else if (data.status === false) {
              this.$swal.fire("Error", data.message, "error");
            } else {
              this.errors = data.errors;
            }
          })
          .catch(function(err) {
            this.$swal("Product", "Error : " + err.message, "error");
          });
      }
    },
  },
};
</script>

<style>
.development-banner {
  background: #f0f331;
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding: 12px;
  border-radius: 8px;
}

.modal-content {
  border-radius: 8px;
}

.modal.fade.show {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.mobile-app-popup {
  position: relative;
  padding: 16px;
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
}

.mobile-app-popup .close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.mobile-app-popup h3,
.mobile-app-popup p {
  text-align: center;
}

.mobile-app-popup .gift-text {
  font-family: "Pacifico", cursive;
  margin: 0 auto;
  margin-bottom: 8px;
  text-align: center;
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  color: #e24616;
}

.mobile-app-popup .gift-text sup {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  top: -20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.3;
  color: #3b3b3b;
}

#modal_ul {
  list-style: none;
}
#modal_ul li {
  margin-top: 12px;
  font-size: 14px;
}
#modal_ul li:before {
  content: "✓";
  padding-right: 10px;
}
.cursor-pointer {
  cursor: pointer;
}
.modal-head {
  color: #ff0000 !important;
  font-weight: bold !important;
}
.btn-info1 {
  color: #fff !important;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}
.btn-info1 a {
  color: #fff !important;
  text-transform: capitalize !important;
}
.custom-modal {
  border-radius: 50px !important;
  border: 2px solid #ffc000 !important;
}
.category-holder1 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  -webkit-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.3);
}

/* Wizard Section Styles */
.wizard-section {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.wizard-section img {
  display: block;
  width: 72px;
  height: auto;
}

.wizard-section .button {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  padding: 8px 24px;
  background: #fe3c00;
  color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.wizard-section .text {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #000000;
}

.wizard-section .text .blue {
  font-size: inherit;
  color: #35cee6 !important;
}

/* Best Sellers Section Styles */
.best-sellers-section {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.best-sellers-section .best-sellers-section-title {
  font-size: 24px;
  font-weight: 600;
  color: #fe3c00;
  margin-bottom: 12px;
}

.best-sellers-empty-state {
  font-size: 16px;
  font-weight: 500;
  color: #666666;
}

.best-sellers-section .best-sellers {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 24px;
}

.best-sellers-section .best-sellers .best-seller {
  position: relative;
  padding: 64px 24px;
  border-radius: 4px;
  border: 1px solid #dadada;
  overflow: hidden;
  cursor: pointer;
}

.best-sellers-section .best-sellers .best-seller:hover {
  border: 1px solid #fe3c00;
}

.best-sellers-section .best-sellers .best-seller .image-wrapper {
  position: relative;
  max-width: 100%;
  height: 240px;
  object-fit: cover;
}

.best-sellers-section .best-sellers .best-seller .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.best-sellers-section .best-sellers .best-seller:hover .image-wrapper img {
  opacity: 0.25;
}

.best-sellers-section .best-sellers .best-seller .image-wrapper .icons {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.best-sellers-section .best-sellers .best-seller:hover .image-wrapper .icons {
  display: flex;
}

.best-sellers-section .best-sellers .best-seller .image-wrapper .icons .icon {
  width: 36px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  fill: #fe3c00;
}

.best-sellers-section
  .best-sellers
  .best-seller
  .image-wrapper
  .icons
  .icon
  svg {
  width: 24px;
}

.best-sellers-section .best-sellers .best-seller:hover .image-wrapper .icons {
  display: flex;
}

.best-sellers-section .best-sellers .best-seller .actions {
  position: absolute;
  top: 0;
  left: 12px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.best-sellers-section .best-sellers .best-seller .actions .action {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  transform: rotateX(90deg);
  background: #ffffff;
  color: #fe3c00;
  transform-origin: top;
  cursor: pointer;
  transition: all 0.2s;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.2);
}

.best-sellers-section .best-sellers .best-seller:hover .action {
  transform: rotateX(0);
}

.best-sellers-section .best-sellers .best-seller .actions .action:nth-child(2) {
  transition-delay: rotate 0.1s;
}

.best-sellers-section .best-sellers .best-seller .actions .action:nth-child(3) {
  transition-delay: rotate 0.2s;
}

.best-sellers-section .best-sellers .best-seller .actions .action:last-child {
  transition-delay: rotate 0.3s;
}

.best-sellers-section .best-sellers .best-seller .action:hover {
  background: #fe3c00;
  fill: #ffffff;
}

.best-sellers-section .best-sellers .best-seller .trending-product-wishlist {
  position: absolute;
  top: 12px;
  right: 12px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  fill: #fe3c00;
  border: 1px solid #fe3c00;
  padding: 4px;
  border-radius: 4px;
}

.best-sellers-section .best-sellers .best-seller .product-line {
  margin: 20px 0;
  width: 100%;
  height: 1px;
  background: linear-gradient(
    to right,
    #dadada 42%,
    #fe3c00 42%,
    #fe3c00 58%,
    #dadada 58%
  );
}

.best-sellers-section .best-sellers .best-seller .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.best-sellers-section .best-sellers .best-seller .content .best-seller-rating {
  display: flex;
  align-items: center;
  gap: 4px;
  fill: #ffc000;
}

.best-sellers-section .best-sellers .best-seller .content .best-seller-title {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 1.3;
  color: #666666;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.best-sellers-section .best-sellers .best-seller .content .best-seller-price {
  color: #fe3c00;
  font-size: 16px;
  font-weight: 500;
}

@media screen and (max-width: 1280px) {
  .best-sellers-section .best-sellers {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
}

@media screen and (max-width: 992px) {
  .best-sellers-section .best-sellers {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 640px) {
  .best-sellers-section .best-sellers {
    grid-template-columns: 1fr;
  }

  .best-sellers-section .best-sellers .best-seller .actions .action {
    transform: rotateX(0);
  }
}

/* Customer Slider Section Styles */
.home-customer-slider-wrapper {
  position: relative;
  margin: 40px 0;
  padding: 30px 0px;
  white-space: nowrap;
  overflow: hidden;
}

.home-customer-slider {
  width: max-content;
  display: inline-block;
  animation-name: slides;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.home-customer-slider img {
  height: 60px;
  margin: 0 10px;
}

@media screen and (max-width: 1280px) {
  .home-customer-slider img {
    height: 40px;
  }
}

@keyframes slides {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>
